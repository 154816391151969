import React from "react";
import { Button } from "@zendeskgarden/react-buttons";
import styled from "styled-components";

type Props = {
  title: string | React.ReactNode;
  description: string | React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm(): void;
  onCancel(): void;
};

/** Modal prompt dialog. */
export const FullViewPrompt = (props: Props) => {
  const { title, description, onCancel, onConfirm, confirmLabel, cancelLabel } = props;

  return (
    <Modal>
      <Title>{title}</Title>
      <div>{description}</div>

      <Buttons>
        <Button isPrimary type="submit" onClick={onConfirm}>
          {confirmLabel || "Confirm"}
        </Button>
        <Button isBasic onClick={onCancel}>
          {cancelLabel || "Cancel"}
        </Button>
      </Buttons>
    </Modal>
  );
};

const Modal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;

  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 6px;

  background: #fff;
`;

const Title = styled.div`
  font-weight: 600;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: 24px;

  > button:first-child {
    margin-right: 12px;
  }
`;
