import React, { useEffect } from "react";
import { ThemeProvider, DEFAULT_THEME } from "@zendeskgarden/react-theming";
import { ToastProvider } from "@zendeskgarden/react-notifications";
import { createGlobalStyle } from "styled-components";
import { zendesk } from "./zendesk";
import { Root } from "./views/Root";
import "@zendeskgarden/css-bedrock/dist/index.css";

const { PROD } = import.meta.env;

function App() {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).ZENDESK_CLIENT = zendesk;
  }, []);

  // Render empty view in prod if accessed outside Zendesk iframe
  if (PROD && !zendesk) {
    return <div />;
  }

  return (
    <>
      <ThemeProvider>
        <ToastProvider placementProps={placementProps} limit={1} zIndex={1}>
          <Root />
        </ToastProvider>
      </ThemeProvider>
      <GlobalStyle />
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  html {
    min-height: auto;
    overflow-y: auto;
  }
`;

const topProps = {
  style: { top: DEFAULT_THEME.space.base * 3 },
} as React.HTMLAttributes<HTMLDivElement>;
const bottomProps = {
  style: { bottom: DEFAULT_THEME.space.base * 3 },
} as React.HTMLAttributes<HTMLDivElement>;
const placementProps = {
  "top-start": topProps,
  top: topProps,
  "top-end": topProps,
  "bottom-start": bottomProps,
  bottom: bottomProps,
  "bottom-end": bottomProps,
};

export default App;
