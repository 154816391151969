import React from "react";
import styled from "styled-components";
import { Field, Label, Textarea, Toggle } from "@zendeskgarden/react-forms";
import { Item, Menu, Dropdown, Autocomplete, Field as DropdownField } from "@zendeskgarden/react-dropdowns";
import { Formik, Field as FormikField, Form, type FormikHelpers, type FormikProps, type FieldProps } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "urql";
import { Button } from "@zendeskgarden/react-buttons";
import throttle from "lodash/throttle";
import {
  CreateAttachmentMutation,
  CreateCommentMutation,
  type ICreateAttachmentMutation,
  type ICreateCommentMutation,
  type ISearchIssueQuery,
  type Issue,
  SearchIssueQuery,
} from "../queries";
import type { ZendeskTicket } from "../types";
import { Constants } from "../constants";
import { attachmentForZendeskConversation } from "../utils/formattingUtils";
import { SearchIcon } from "../components/SearchIcon";
import { colors } from "../utils/styles";
import { IssueWidget } from "../components/IssueWidget";

const LinkIssueSchema = Yup.object().shape({
  issue: Yup.object().required("Required"),
});

type Props = {
  ticket: ZendeskTicket;
  onLink(): void;
  onCancel(): void;
  issue?: Issue;
};

interface FormValues {
  issue?: Issue;
  comment: string;
  includeComment?: boolean;
}

export function LinkIssue(props: Props) {
  const { ticket, onLink, onCancel, issue: initialIssue } = props;
  const includeComment = localStorage.getItem(Constants.commentCacheKey) === "true" || false;

  const [createAttachmentResult, createAttachment] = useMutation<ICreateAttachmentMutation>(CreateAttachmentMutation);
  const [createCommentResult] = useMutation<ICreateCommentMutation>(CreateCommentMutation);
  const fetching = createAttachmentResult.fetching || createCommentResult.fetching;

  const handleSubmit = async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (!values.issue || fetching) {
      setSubmitting(false);
      return;
    }
    const commentBody = values.includeComment && values.comment?.length ? values.comment : undefined;

    localStorage.setItem(Constants.commentCacheKey, values.includeComment ? "true" : "false");
    await createAttachment({
      ...attachmentForZendeskConversation(values.issue.id, ticket),
      commentBody,
    });

    setSubmitting(false);
    onLink();
  };

  const handleKeyboardSubmit = (event: React.KeyboardEvent, submit: () => void) => {
    if (event.metaKey && event.key === "Enter") {
      submit();
    }
  };

  return (
    <Container>
      <Formik
        initialValues={{
          comment: "",
          includeComment,
          issue: initialIssue,
        }}
        validationSchema={LinkIssueSchema}
        onSubmit={handleSubmit}
      >
        {(form: FormikProps<FormValues>) => (
          <StyledForm onKeyDown={e => handleKeyboardSubmit(e, form.submitForm)}>
            <IssueDropdown
              onSelect={value => {
                form.setFieldValue("issue", value);
              }}
            />

            {form.values.issue && (
              <>
                <IssueWidget issue={form.values.issue} />

                <Field>
                  <FormikField name="includeComment" type="checkbox">
                    {({ field }: FieldProps<string, FormValues>) => (
                      <Toggle id="includeComment" {...field}>
                        <Label htmlFor="includeComment" isRegular>
                          Include comment
                        </Label>
                      </Toggle>
                    )}
                  </FormikField>
                </Field>

                {form.values.includeComment && (
                  <Field>
                    <FormikField name="comment">
                      {({ field }: FieldProps<string, FormValues>) => (
                        <Textarea aria-label="Comment" minRows={5} maxRows={10} isCompact autoFocus {...field} />
                      )}
                    </FormikField>
                  </Field>
                )}
              </>
            )}

            <Buttons>
              <Button isPrimary type="submit" disabled={form.values.issue === undefined || form.isSubmitting}>
                {form.isSubmitting ? "Linking…" : "Link ticket"}
              </Button>
              <Button isBasic onClick={onCancel}>
                Cancel
              </Button>
            </Buttons>
          </StyledForm>
        )}
      </Formik>
    </Container>
  );
}

const Container = styled.div`
  padding: 4px;
  max-width: 100%;
  min-height: 350px;
  overflow: auto;
`;

const StyledForm = styled(Form)`
  > div {
    margin-bottom: 18px;
  }
`;

const Buttons = styled.div`
  display: flex;

  > button:first-child {
    margin-right: 12px;
  }
`;

interface IssueDropdownProps {
  onSelect(issue: Issue): void;
}

/**
 * Dropdown to search issues.
 */
const IssueDropdown = (props: IssueDropdownProps) => {
  const [searchQuery, setSearchQuery] = React.useState<string>("");

  // Throttle input
  const [throttledQuery, setThrottledQuery] = React.useState<string>("");
  const throttled = React.useRef(throttle(newValue => setThrottledQuery(newValue), 500));
  React.useEffect(() => throttled.current(searchQuery), [searchQuery]);

  const [result] = useQuery<ISearchIssueQuery>({
    query: SearchIssueQuery,
    variables: { searchQuery: throttledQuery, includeComments: true },
    pause: searchQuery === undefined,
  });
  const { fetching } = result;
  const issues = (result.fetching === false && result.data?.searchIssues.nodes) || [];

  return (
    <Dropdown
      inputValue={searchQuery}
      onInputValueChange={value => setSearchQuery(value)}
      downshiftProps={{ defaultHighlightedIndex: 0 }}
      onSelect={value => props.onSelect?.(issues.find(issue => issue.id === value) as Issue)}
    >
      <DropdownField>
        <Autocomplete start={<StyledSearchIcon />}>
          <span style={{ color: colors.placeholderText }}>Search issues…</span>
        </Autocomplete>
      </DropdownField>
      <Menu isCompact maxHeight="250px" placement="bottom-start">
        {issues.length ? (
          issues.map(issue => (
            <TruncatedItem key={issue.id} value={issue.id}>
              {issue.identifier} {issue.title}
            </TruncatedItem>
          ))
        ) : (
          <TruncatedItem disabled>
            {searchQuery.length ? (fetching ? "Searching…" : "No matching issue found") : "Type to search…"}
          </TruncatedItem>
        )}
      </Menu>
    </Dropdown>
  );
};

const StyledSearchIcon = styled(SearchIcon)`
  width: 14px;
`;

const TruncatedItem = styled(Item)`
  padding: 4px 12px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
