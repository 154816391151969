import * as React from "react";
import { Skeleton } from "@zendeskgarden/react-loaders";
import { Notification, Title, Close, useToast } from "@zendeskgarden/react-notifications";
import { useQuery } from "urql";
import { type ITicketSummaryQuery, TicketSummaryQuery } from "~/queries";

interface Props {
  body: string;
  children(props: { title?: string; description?: string }): JSX.Element;
}

/**
 * Load AI generated summary for a ticket.
 */
export const LoadTicketSummary = ({ body, children }: Props) => {
  const { addToast } = useToast();
  const [result] = useQuery<ITicketSummaryQuery>({
    query: TicketSummaryQuery,
    variables: { body },
  });
  const loaded = result.fetching === false;

  React.useEffect(() => {
    if (result.error) {
      addToast(({ close }) => <UnableToSummarizeError close={close} />, { placement: "bottom-end" });
    }
  }, [result.error, addToast]);

  return loaded ? (
    result.data ? (
      children(result.data.supportTicketSummary)
    ) : (
      children({})
    )
  ) : (
    <div>
      <Skeleton height="18px" width="15%" />
      <Skeleton height="42px" width="100%" />
      <Skeleton height="18px" width="25%" />
      <Skeleton height="94px" width="100%" />
    </div>
  );
};

const UnableToSummarizeError = ({ close }: { close(): void }) => {
  React.useEffect(() => {
    const timer = setTimeout(close, 2500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Notification type="warning" style={{ maxWidth: 450 }}>
      <Title>Unable to summarize</Title>
      <div>Content of the ticket couldn't be summarized.</div>
      <Close aria-label="Close" onClick={close} />
    </Notification>
  );
};
