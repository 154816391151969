import React from "react";
import styled from "styled-components";
import { timeAgo } from "~/utils/timeAgoLocaleEn";
import type { Issue } from "../queries";

type Props = {
  // Linear issue to be shown.
  issue: Issue;
  // Callback to be executed after clicking the link issue button
  onLink(): void;
};

/**
 * A concise view of an issue with limited properties.
 */
export const CompactIssueCard = (props: Props) => {
  const { issue, onLink } = props;
  const createdAt = new Date(issue.createdAt);
  const updatedAt = new Date(issue.updatedAt);

  const handleLink = (event: React.MouseEvent) => {
    event.preventDefault();
    onLink();
  };

  return (
    <Container>
      <Header href={issue.url} target="_blank" rel="noopener noreferrer">
        {issue.identifier} {issue.title}
      </Header>
      <Details>
        <span>
          <Updated>Status: {issue.state.name}</Updated>
          <Separator>&middot;</Separator>
          <Updated>Created: {timeAgo.format(createdAt, "round")}</Updated>
          {issue.createdAt !== issue.updatedAt && (
            <>
              <Separator>&middot;</Separator>
              <Updated>Updated: {timeAgo.format(updatedAt, "round")}</Updated>
            </>
          )}
        </span>
        <Link onClick={handleLink} href="">
          Link issue
        </Link>
      </Details>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d9dcde;
  border-radius: 4px;
  padding: 12px;
`;

const Header = styled.a`
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
`;

const Separator = styled.span`
  padding: 0 6px;
  color: #777777;
`;

const Link = styled.a`
  font-size: 12px;
`;

const Updated = styled.span`
  font-size: 12px;
  color: #90959d;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
`;
