import React from "react";
import { Button } from "@zendeskgarden/react-buttons";
import { differenceInMinutes } from "date-fns";
import { useMutation } from "urql";
import styled from "styled-components";
import {
  oauthAuthStringCheckMutation,
  oauthAuthStringChallengeMutation,
  type OauthAuthStringChallengePayload,
  type OauthAuthStringCheckPayload,
} from "~/queries";
import { Config } from "../config";
import { zendesk } from "../zendesk";

const OAUTH_SCOPE = ["read", "write"];
const authUrl = `${Config.OAUTH_URL}?client_id=${Config.OAUTH_CLIENT_ID}&response_type=stringauth&redirect_uri=${
  Config.OAUTH_REDIRECT_URI
}&scope=${OAUTH_SCOPE.join(",")}`;

type Props = {
  onSuccess(accessToken: string): void;
};

export function LoginButton({ onSuccess }: Props) {
  const [authString, setAuthString] = React.useState<string>();
  const [, challengeMutation] = useMutation<OauthAuthStringChallengePayload>(oauthAuthStringChallengeMutation);

  React.useEffect(() => {
    zendesk.invoke("resize", { width: "100%", height: "200px" });
  }, []);

  const handleLogin = async () => {
    const challengeMutationResult = await challengeMutation({
      appId: Config.OAUTH_CLIENT_ID,
      scope: OAUTH_SCOPE,
    });
    setAuthString(challengeMutationResult.data?.oauthAuthStringChallenge.authString);
  };

  const handleReset = () => {
    setAuthString(undefined);
  };

  return (
    <div>
      {authString ? (
        <AuthString authString={authString} onSuccess={onSuccess} onReset={handleReset} />
      ) : (
        <Button isStretched onClick={handleLogin}>
          Log in with Linear
        </Button>
      )}
    </div>
  );
}

const AuthString = (props: { authString: string; onSuccess(accessToken: string): void; onReset(): void }) => {
  const { authString, onSuccess, onReset } = props;
  const [startedPollingAt] = React.useState<Date>(new Date());
  const [, checkMutation] = useMutation<OauthAuthStringCheckPayload>(oauthAuthStringCheckMutation);

  const poll = async () => {
    // Stop polling and reset state after the token expires anyway.
    if (differenceInMinutes(new Date(), startedPollingAt) >= 5) {
      onReset();
      return;
    }

    const checkMutationResult = await checkMutation({
      appId: Config.OAUTH_CLIENT_ID,
      authString,
    });

    if (
      checkMutationResult.data?.oauthAuthStringCheck.success &&
      checkMutationResult.data?.oauthAuthStringCheck.token
    ) {
      onSuccess(checkMutationResult.data?.oauthAuthStringCheck.token);
      return;
    }
  };

  React.useEffect(() => {
    if (!authString) {
      return;
    }

    // Open the oAuth flow screen and provide the auth string.
    window.open(
      authUrl + `&authstring=${authString}`,
      "authWindow",
      `height=${600},width=${500},top=${screen.height / 2 - 300},left=${screen.width / 2 - 250}`
    );

    const interval = setInterval(poll, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Message>
      <Title>Waiting for log in…</Title>
      We've opened Linear in another window. <br />
      Return here when you've completed authorization.
      <Button isBasic isStretched onClick={onReset}>
        Cancel
      </Button>
    </Message>
  );
};

const Title = styled.h2`
  font-weight: 600;
  margin-bottom: 1rem;
  font-size: 14px;
  color: #333;
`;

const Message = styled.div`
  padding: 2rem 1rem;
  font-size: 12px;
  color: #777777;
  text-align: center;
`;
