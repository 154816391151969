import React from "react";

export const SearchIcon = (props: {}) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
      <circle cx="6" cy="6" r="5" fill="none" stroke="currentColor" strokeWidth="2" />
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M15 15l-5-5" />
    </svg>
  );
};
