import React from "react";
import { formatMessage, formatTicketUrl } from "../utils/formattingUtils";
import type { ZendeskConversationMessage, ZendeskRequester, ZendeskTicket, ZendeskTicketMessage } from "../types";
import { zendesk } from "../zendesk";

type Props = {
  children(props: { ticket: ZendeskTicket }): JSX.Element;
};

/**
 * Load Zendesk ticket data from the parent before showing the app
 */
export function LoadZendeskData(props: Props) {
  const [ticket, setTicket] = React.useState<ZendeskTicket | undefined>();

  React.useEffect(() => {
    zendesk.invoke("resize", { width: "100%", height: "610px" });
    void fetchData();
  }, []);

  const fetchData = async () => {
    const [zdData, contextData] = await Promise.all([
      zendesk.get([
        "ticket.id",
        "ticket.type",
        "ticket.priority",
        "ticket.subject",
        "ticket.description",
        "ticket.status",
        "ticket.createdAt",
        "ticket.brand.url",
        "ticket.requester",
        "ticket.conversation",
      ]),
      zendesk.context(),
    ]);
    if (zdData && contextData) {
      const requester = zdData["ticket.requester"] as ZendeskRequester;
      const conversationMessages = zdData["ticket.conversation"] as ZendeskConversationMessage[];
      const firstMessage = conversationMessages[0];
      const messages: ZendeskTicketMessage[] = [];
      if (firstMessage) {
        const authorName = firstMessage.author.name;
        messages.push({
          subject: authorName === requester.name && requester.email ? `${authorName} (${requester.email})` : authorName,
          body: formatMessage(firstMessage),
          timestamp: firstMessage.timestamp,
        });
      }

      setTicket({
        id: zdData["ticket.id"],
        type: zdData["ticket.type"],
        priority: zdData["ticket.priority"],
        domain: zdData["ticket.brand.url"],
        url: formatTicketUrl(contextData.account.subdomain, zdData["ticket.id"]),
        subject: zdData["ticket.subject"],
        status: zdData["ticket.status"],
        createdAt: new Date(zdData["ticket.createdAt"]),
        messages,
      });
    }
  };

  return ticket ? props.children({ ticket }) : <div />;
}
