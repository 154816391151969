import React from "react";
import {
  cacheExchange,
  createClient,
  dedupExchange,
  errorExchange,
  fetchExchange,
  Provider as UrqlProvider,
} from "urql";
import { LoginButton } from "../components/LoginButton";
import { Constants } from "../constants";
import { Config } from "../config";
import { LoadZendeskData } from "./LoadZendeskData";
import { Main } from "./Main";

export function Root() {
  const [accessToken, setAccessToken] = React.useState<string | null>(localStorage.getItem(Constants.AUTH_TOKEN));
  const ref = React.useRef<HTMLDivElement>(null);

  const handleLogin = (token: string) => {
    setAccessToken(token);
    localStorage.clear();
    localStorage.setItem(Constants.AUTH_TOKEN, token);
  };

  const handleLogout = () => {
    localStorage.clear();
    setAccessToken(null);
  };

  const client = React.useMemo(
    () =>
      createClient({
        url: Config.GRAPHQL_URL,
        fetchOptions: () => {
          const token = localStorage.getItem(Constants.AUTH_TOKEN);
          return {
            headers: { authorization: token ? `Bearer ${token}` : "" },
          };
        },
        exchanges: [
          dedupExchange,
          cacheExchange,
          errorExchange({
            onError(error) {
              // 401 - Log out the user
              if (error.message.includes("authentication failed")) {
                setAccessToken(null);
              }
            },
          }),
          fetchExchange,
        ],
      }),
    [accessToken]
  );

  return (
    <div ref={ref}>
      <UrqlProvider value={client}>
        {accessToken ? (
          <LoadZendeskData>{({ ticket }) => <Main ticket={ticket} onLogout={handleLogout} />}</LoadZendeskData>
        ) : (
          <LoginButton onSuccess={handleLogin} />
        )}
      </UrqlProvider>
    </div>
  );
}
